import React from 'react'

function Contact() {
    return (
        <>
            <p>Kontakt</p>
            <div className="flex-container" aria-live="rude">
                <div className="contact-area">
                    <div className="contact-name">
                        Die Einrichtung:<br/><br/>
                    </div>
                    <div className="contact-information">
                        <p>
                            Stiftung Haus Pius XII<br/>
                            Kinder- und Jugendhilfeeinrichtung<br/>
                            Sophie-Charlotte-Straße 31<br/>
                            14169 Berlin<br/>	<br/>
                        </p>
                        <p>
                            Tel.: <a href="tel:+49-30-81-81-85-0">+49 (0)30 81 81 85 - 0</a><br/>
                            Fax: +49-30-81 81 85 - 12<br/>
                            E-Mail: <a href="mailto:more@stiftung-haus-pius.de">more@stiftung-haus-pius.de</a><br/>
                        </p>
                    </div>
                </div>
                <div className="contact-area">
                    <div className="contact-name">
                        Geschäftsführung:<br/><br/>
                    </div>
                    <div className="contact-information">
                        <p>
                            Michael A. Lencz <br/><br/><br/><br/><br/>
                        </p>
                        <p>
                            Tel.: <a href="tel:+49-30-81-81-85-23">+49 (0)30 81 81 85 - 23</a><br/>
                            Fax: +49-30-81 81 85 - 28<br/>
                            E-Mail: <a href="mailto:geschaeftsfuehrung@stiftung-haus-pius.de?subject=Aus Homepage Kontakt">geschaeftsfuehrung@stiftung-haus-pius.de</a>
                        </p>
                    </div>
                </div>  
                <div className="contact-area">
                    <div className="contact-name">
                        Pädagogische Gesamtleitung:<br/><br/>
                    </div>
                    <div className="contact-information">
                        <p>
                        Waltraud Schmelzer<br/><br/><br/><br/><br/>
                        </p>
                        <p>
                        Tel.: <a href="tel:+49-30-81-81-85-0">+49 (0)30 81 81 85 - 0</a><br/>
                        Fax: +49-30-81 81 85 - 12<br/>
                        E-Mail: <a href="mailto:paed.-leitung@stiftung-haus-pius.de?subject=Aus Homepage Kontakt">paed.-leitung@stiftung-haus-pius.de</a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
