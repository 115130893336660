import './Disclaimer.scss';

function Disclaimer() {
    return (
        <>
            <h1>Datenschutzinformationen</h1>
            <p>
                Wir freuen uns über Ihren Besuch auf unseren Webseiten. Nachfolgend möchten wir Sie über den Umgang mit Ihren Daten gemäß § 15 des Gesetzes über den Kirchlichen Datenschutz (KDG) informieren.
            </p>
            
            <h1>Verantwortlicher</h1>
            <p>
                Verantwortlich für die nachfolgend beschriebene Datenverarbeitung ist:
            </p>
            <br/>
            <p>
                Stiftung Haus Pius XII<br/>
                Sophie-Charlotte-Straße 31<br/>
                14169 Berlin<br/>
                E-Mail: geschaeftsfuehrung@stiftung-haus-pius.de
            </p>

            <h1>Nutzungsdaten</h1>
            <p>
                Wenn Sie unsere Webseiten besuchen, verarbeiten wir temporär so genannte Nutzungsdaten. Die Nutzungsdaten verwenden wir zu statistischen Zwecken, um die Qualität unserer Webseiten zu verbessern sowie zu Sicherheitszwecken. Wir verwenden diese Informationen außerdem, um den Aufruf unserer Webseite zu ermöglichen, zur Kontrolle und Administration unserer Systeme sowie zur Verbesserung der Gestaltung der Webseiten.
            </p>
            <br/>
            <p>
                Der Datensatz besteht aus:
            </p>
            <ul>
                <li>dem Namen und der Adresse der angeforderten Inhalte</li>
                <li>dem Datum und der Uhrzeit der Abfrage</li>
                <li>der übertragenen Datenmenge</li>
                <li>dem Zugriffsstatus (Inhalt übertragen, Inhalt nicht gefunden)</li>
                <li>der Beschreibung des verwendeten Webbrowsers und des Betriebssystems</li>
                <li>dem Referral-Link, der angibt, von welcher Seite Sie auf unsere gelangt sind</li>
                <li>der IP-Adresse des anfragenden Rechners</li>
            </ul>

            <h1>Speicherung der IP-Adresse</h1>
            <p>
                Die von Ihrem Webbrowser übermittelte IP-Adresse speichern wir für die Dauer von sieben Tagen in dem Interesse, Angriffe auf unsere Webseiten erkennen, eingrenzen und beseitigen zu können. Nach Ablauf dieser Zeitspanne löschen bzw. anonymisieren wir die IP-Adresse. Rechtsgrundlage für die Verarbeitung ist § 6 Abs. 1 lit. g KDG.
            </p>

            <h1>Cookies</h1>
            <p>
                Auf unseren Webseiten verwenden wir Cookies, die zur Nutzung unserer Webseiten erforderlich sind. Cookies sind kleine Textdateien, die auf Ihrem Endgerät gespeichert und ausgelesen werden können. Man unterscheidet zwischen Session-Cookies, die wieder gelöscht werden, sobald Sie Ihren Browser schließen, und permanenten Cookies, die über die einzelne Sitzung hinaus gespeichert werden.
            </p>
            <br/>
            <p>
                Wir nutzen Cookies nicht für Analyse-, Tracking- oder Werbezwecke. Die von uns verwendeten Cookies enthalten Informationen zu bestimmten Einstellungen, erleichtern die Benutzerführung oder sind für die Sicherheit der Seite erforderlich. Wir nutzen die von uns verwendeten Cookies auf Grundlage von § 25 Abs. 2 Nr. 2 TDDDG.
            </p>
            <br/>
            <p>
                Sie können Ihren Browser so einstellen, dass er Sie über die Platzierung von Cookies informiert. So wird der Gebrauch von Cookies für Sie transparent. Sie können Cookies zudem jederzeit über die entsprechende Browsereinstellung löschen und das Setzen neuer Cookies verhindern. Bitte beachten Sie, dass unsere Webseiten dann ggf. nicht angezeigt werden können und einige Funktionen technisch nicht mehr zur Verfügung stehen.
            </p>

            <h1>Einwilligungsbanner</h1>
            <p>
                Auf unserer Webseite nutzen wir die Consent-Management-Plattform (Einwilligungs- bzw. Cookie-Banner) Cookiebot des Anbieters Usercentrics A/S, um Ihre Einwilligung für das Speichern oder Auslesen von Informationen und in die Verarbeitung Ihrer Daten einzuholen, soweit dies erforderlich ist. Die Verarbeitungen im Zusammenhang mit dem Einsatz der Consent-Management-Plattform sowie der Protokollierung der von Ihnen vorgenommenen Einstellungen erfolgt auf Grundlage von § 6 Abs. 1 lit. g KDG (Interessenabwägung, basierend auf unserem Interesse, Inhalte entsprechend Ihren Präferenzen auszuspielen und die Erteilung einer Einwilligung nachweisen zu können). Ihre vorgenommenen Einstellungen, die damit erteilten Einwilligungen sowie Teile Ihrer Nutzungsdaten werden in einem Cookie gespeichert. Damit bleibt dieser für nachfolgende Seitenanfragen erhalten und Ihre Einwilligungen können weiterhin nachvollzogen werden.
            </p>
            <br/>
            <p>
                Der Anbieter der Consent-Management-Plattform ist für uns als weisungsgebundener Dienstleister (Auftragsverarbeiter) tätig. Ein Auftragsverarbeitungsvertrag gemäß § 29 KDG wurde abgeschlossen.
            </p>

            <h1>Kontaktaufnahme</h1>
            <p>
                Sie haben die Möglichkeit, sich per Brief, E-Mail oder Telefon mit uns in Verbindung zu setzen. Für Zwecke der Kontaktaufnahme verarbeiten wir grundsätzlich diese Kategorien personenbezogener Daten: Name, Kontaktdaten (Anschrift, E-Mail-Adresse, Telefonnummer), Anliegen. Diese Daten verwenden wir auf Grundlage von § 6 Abs. 1 lit. g KDG, um Ihre Anfrage zu beantworten. Wir löschen Ihre Daten, sofern diese nicht mehr erforderlich sind und gesetzliche Aufbewahrungspflichten nicht entgegenstehen.
            </p>

            <h1>Spenden</h1>
            <p>
                Sie können uns mit Spenden unterstützen. Mit Ihrer Spende können Sie ein konkretes Projekt oder allgemein die Stiftungsarbeit unterstützen. Wenn Sie uns eine Spende zukommen lassen, verarbeiten wir Ihren Namen, Ihre Anschrift, ggf. Ihre E-Mail-Adresse, Ihre Kontodaten, den Spendenbetrag, das Spendendatum und den Verwendungszweck. Die Daten verarbeiten wir für Zwecke der Spendenabwicklung sowie für die Ausstellung und den Versand der Spendenquittung. Ihre Zahlungsdaten werden an unsere Hausbank SozialBank AG weitergegeben, um die Abwicklung Ihrer Spende zu ermöglichen.
            </p>
            <br/>
            <p>
                Rechtsgrundlagen für die Datenverarbeitung sind § 6 Abs. 1 lit. c KDG (Vertragserfüllung), § 6 Abs. 1 lit. g KDG (Interessenabwägung, basierend auf unserem Interesse, Spenden ordnungsgemäß abzuwickeln, unsere Rechnungslegungspflichten zu erfüllen und die ordnungsgemäße Verwendung der Spenden nachweisen zu können) sowie § 6 Abs. 1 lit. d KDG (Erfüllung gesetzlicher Aufbewahrungspflichten).
            </p>
            <br/>
            <p>
                Ihre Daten löschen wir grundsätzlich nach Ablauf von zehn Jahren.
            </p>

            <h1>Kartendienst</h1>
            <p>
                Auf unserer Webseite betten wir einen Kartendienst ein, der nicht auf unserem Server gespeichert ist. Ein Aufruf unserer Seite mit eingebettetem Kartendienst führt dazu, dass Inhalte des Drittanbieters nachgeladen werden, der die Kartendienste bereitstellt. Hierdurch erhält der Drittanbieter die Information, dass Sie unsere Seite aufgerufen haben sowie die in diesem Rahmen technisch erforderlichen Nutzungsdaten.
            </p>
            <br/>
            <p>
                Die Einbettung erfolgt auf Grundlage Ihrer Einwilligung gemäß § 25 Abs. 1 TDDDG und § 6 Abs. 1 lit. b KDG.
            </p>
            <br/>
            <p>
                Sofern die Daten außerhalb der EU bzw. des EWR (insbesondere in den USA) verarbeitet werden, machen wir in der nachfolgenden Tabelle Angaben zum Datenschutzniveau.
            </p>
            
            <table>
                <thead>
                    <tr>
                        <th>Anbieter</th>
                        <th>Kartendienst</th>
                        <th>Angemessenes Datenschutzniveau</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Google Ireland Limited (Irland), Google LLC (USA)</td>
                        <td>Google Maps</td>
                        <td>Für Übermittlungen in die USA ist ein angemessenes Datenschutzniveau aufgrund der Zertifizierung des Anbieters nach dem EU-U.S. Data Privacy Framework gewährleistet (Angemessenheitsbeschluss der Europäischen Kommission).</td>
                    </tr>
                </tbody>
            </table>

            <h1>Speicherdauer</h1>
            <p>
                Sofern wir nicht im Einzelnen bereits über die Speicherdauer informiert haben, löschen wir personenbezogene Daten, wenn sie für die vorgenannten Verarbeitungszwecke nicht mehr erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
            </p>

            <h1>Auftragsverarbeiter</h1>
            <p>
                Wir geben Ihre Daten im Rahmen einer Auftragsverarbeitung gemäß § 28 KDG an Dienstleister weiter, die uns beim Betrieb unserer Webseiten und der damit zusammenhängenden Prozesse unterstützen. Von uns eingesetzte Dienstleister müssen besondere Vertraulichkeitsanforderungen erfüllen. Sie erhalten nur in dem Umfang und für den Zeitraum Zugang zu Ihren Daten, der für die Erfüllung der übertragenen Aufgaben erforderlich ist. Für den Betrieb unserer Webseite setzen wir diesen Dienstleister ein:
            </p>
            
            <table>
                <thead>
                    <tr>
                        <th>Auftragsverarbeiter</th>
                        <th>Zweck</th>
                        <th>Angemessenes Datenschutzniveau</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>IONOS SE</td>
                        <td>Webhosting und Support</td>
                        <td>Verarbeitung nur innerhalb EU/EWR</td>
                    </tr>
                </tbody>
            </table>

            <h1>Datensicherheit</h1>
            <p>
                Um Ihre Daten vor unerwünschten Zugriffen möglichst umfassend zu schützen, treffen wir technische und organisatorische Maßnahmen. Wir setzen auf unseren Webseiten ein Verschlüsselungsverfahren ein. Ihre Angaben werden von Ihrem Rechner zu unserem Server und umgekehrt über das Internet mittels einer TLS-Verschlüsselung übertragen. Sie erkennen dies in der Regel daran, dass in der Statusleiste Ihres Browsers das Schlosssymbol geschlossen ist und die Adresszeile mit https:// beginnt.
            </p>

            <h1>Ihre Rechte</h1>
            <p>
                Bei der Verarbeitung Ihrer personenbezogenen Daten gewährt das Gesetz über den Kirchlichen Datenschutz Ihnen als Webseitennutzer bestimmte Rechte.
            </p>
            
            <h1>Auskunft (§ 17 KDG)</h1>
            <p>
                Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden. Ist dies der Fall, haben Sie das Recht auf Auskunft über diese personenbezogenen Daten und auf die in § 17 KDG im Einzelnen aufgeführten Informationen.
            </p>

            <h1>Berichtigung, Löschung (§§ 18, 19 KDG)</h1>
            <p>
                Sie haben das Recht, unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten und ggf. die Vervollständigung unvollständiger personenbezogener Daten zu verlangen.
            </p>
            <br/>
            <p>
                Sie haben zudem das Recht zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, sofern einer der in § 19 KDG im Einzelnen aufgeführten Gründe zutrifft, z.B. wenn die Daten für die verfolgten Zwecke nicht mehr benötigt werden.
            </p>

            <h1>Einschränkung der Verarbeitung (§ 20 KDG)</h1>
            <p>
                Sie haben das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine der in § 20 KDG aufgeführten Voraussetzungen gegeben ist, z.B. wenn Sie Widerspruch gemäß § 23 KDG gegen die Verarbeitung eingelegt haben oder für die Dauer einer etwaigen Prüfung, ob unsere berechtigten Interessen gegenüber Ihren Interessen als betroffene Person überwiegen.
            </p>

            <h1>Datenübertragbarkeit (§ 22 KDG)</h1>
            <p>
                In bestimmten Fällen, die in § 22 KDG im Einzelnen aufgeführt sind, haben Sie das Recht, die Sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten bzw. die Übermittlung dieser Daten an einen Dritten zu verlangen.
            </p>

            <h1>Widerspruch (§ 23 KDG)</h1>
            <p>
                Werden Daten auf Grundlage von § 6 Abs. 1 lit. f KDG (Datenverarbeitung zur Wahrnehmung einer Aufgabe, die im kirchlichen Interesse liegt oder in Ausübung öffentlicher Gewalt) oder auf Grundlage von § 6 Abs. 1 lit. g KDG (Datenverarbeitung zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten) erhoben, steht Ihnen das Recht zu, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Widerspruch einzulegen.
            </p>
            <br/>
            <p>
                Wir verarbeiten die personenbezogenen Daten dann nicht mehr, es sei denn, es liegen nachweisbar zwingende schutzwürdige Gründe für die Verarbeitung vor, die gegenüber Ihren Interessen, Rechten und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
            </p>

            <h1>Widerruf Ihrer Einwilligung (§ 8 Abs. 6 KDG)</h1>
            <p>
                Sie haben das Recht, eine Einwilligung in die Verarbeitung Ihrer Daten, welche Sie uns gegenüber erteilt haben, jederzeit zu widerrufen. Am einfachsten können Sie eine von Ihnen erteilte Einwilligung widerrufen, indem Sie sich per E-Mail an die oben genannten Kontaktdaten wenden. Der Widerruf der Einwilligung berührt nicht die Rechtmäßigkeit der bis zum Widerruf durchgeführten Verarbeitung Ihrer Daten.
            </p>

            <h1>Beschwerderecht bei der Datenschutzaufsicht</h1>
            <p>
                Sie haben gem. § 48 KDG das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden Daten gegen datenschutzrechtliche Bestimmungen verstößt:
            </p>
            <br/>
            <p>
                Kirchliche Datenschutzaufsicht<br/>
                der ostdeutschen Bistümer und<br/>
                des Katholischen Militärbischofs<br/>
                Badepark 4<br/>
                39218 Schönebeck<br/>
                E-Mail: kontakt@kdsa-ost.de
            </p>

            <h1>Datenschutzbeauftragter</h1>
            <p>
                Unser Datenschutzbeauftragter steht Ihnen gern für Auskünfte oder Anregungen zum Thema Datenschutz zur Verfügung:
            </p>
            <br/>
            <p>
                datenschutz nord GmbH<br/>
                Standort Berlin<br/>
                Kurfürstendamm 212<br/>
                10719 Berlin<br/>
                E-Mail: office@datenschutz-nord.de
            </p>
            <br/>
            <p>
                Wenn Sie sich an unseren Datenschutzbeauftragten wenden, geben Sie hierbei bitte an, dass sich Ihre Anfrage auf den Verantwortlichen Stiftung Haus Pius XII bezieht.
            </p>
        </>
    );
}

export default Disclaimer;