import React, { useState } from 'react'
import Haus_Pius_Vorderansicht from './../../assets/images/zehlendorf/Haus-Pius-Vorderansicht.webp';
import './Tiles.scss';

let checkedForLocal = false;


function ZehlendorfLocation() {
    const [hasMarketing, setHasMarketing] = useState(false);
    const handleCookieClick = () => window.Cookiebot.renew();
  
    function CookibotOnLoad(e) {
        setHasMarketing(e.target.Cookiebot.consent.marketing);
    }

    //debug purpose
    if(window.location.hostname === "localhost"){
        if(!checkedForLocal){
            setHasMarketing(true)
            checkedForLocal = true;
        }
    }else if(window.Cookiebot !== undefined && window.Cookiebot.consent !== undefined && window.Cookiebot.consent.marketing !== undefined && hasMarketing !== window.Cookiebot.consent.marketing){
        setHasMarketing(window.Cookiebot.consent.marketing);
    }

    window.removeEventListener('CookiebotOnLoad',CookibotOnLoad);
    window.addEventListener('CookiebotOnLoad', CookibotOnLoad, false);

    let embedding;

    if (hasMarketing) {
        embedding = <iframe title="Biesdorf" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9727.636292579864!2d13.252896298925155!3d52.44456187838079!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a85a3b4c2e1051%3A0xfe99ce05b53a04b1!2sStiftung%20Haus%20Pius%20XII!5e0!3m2!1sde!2sde!4v1640269315309!5m2!1sde!2sde" width="800" height="600" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>;
      } else {
        embedding =  <div className="cookie-disabled">Erlaube <span className='fancy-link' onClick={handleCookieClick}>Marketing Cookies</span> um auf Google-Maps zugreifen zu können</div>;
    }

    return (
        <div className="text-container zehlendorf-location font-size-15">
                <div className="middle">
                    <h1>Berlin-Zehlendorf – Unsere Einrichtung</h1><br/>
                    <div className='flex-container'>
                        <div>
                            In Berlin-Zehlendorf stehen mehrere geräumige Zimmer im Haus Pius sowie in dem sich auf dem gleichen Grundstück befindlichen Haus Giovanni für insgesamt 15 Kinder und Jugendliche zur Verfügung. Im Haus Pius leben Kinder und Jugendliche im Alter bis zu 18 Jahren. Das Haus Giovanni dient zur Verselbständigung der in unserer Einrichtung betreuten Jugendlichen. Dadurch haben die Kinder und Jugendlichen auch die Möglichkeit einer langfristigen Unterbringung.
                            <br/><br/>
                            Ebenfalls zur Einrichtung gehört ein großer Garten mit Spielgeräten, einem Fußballplatz, Trampolin und einem Mehrzweckplatz (Basketball, Tischtennis etc.). Für die Mobilität stehen ein Kleinbus sowie zwei PKWs zur Verfügung, um damit Termine wahrzunehmen. Ausflüge und jährliche Ferienreisen finden ebenfalls statt.
                            <br/><br/>
                            Im unmittelbaren Sozialraum befinden sich Kindertagesstätten, Schulen aller Schularten sowie Freizeiteinrichtungen. Die Einrichtung ist gut mit öffentlichen Verkehrsmitteln erreichbar.
                        </div>
                        <img className="image  image-size-16" src={Haus_Pius_Vorderansicht} alt="Vorderansicht des Hauses Pius in Berlin Zehlendorf"/>
                     
                
                    </div>

                    <div className="map-responsive">
                        {embedding}
                    </div>
            </div>
        </div>
    )
}

export default ZehlendorfLocation
