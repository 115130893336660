import React from 'react';
import {Link} from 'react-router-dom';

import '../../App.scss';
import '../../Animations.scss';
import {calcHeight} from './../../Utils'

import Footer from '../footer/Footer';
import * as Constants from './../../Constants';

import biesdorf1Img from './../../assets/images/biesdorf/Haus-Biesdorf-Vorderansicht-min.webp';
import biesdorf2Img from './../../assets/images/biesdorf/Haus-Biesdorf-Hinteransicht-min.webp';
import biesdorf3Img from './../../assets/images/biesdorf/Küche-Haus-Biesdorf-min.webp';
import biesdorf4Img from './../../assets/images/biesdorf/Esszimmer-Haus-Biesdorf-min.webp';
import zehlendorf1Img from './../../assets/images/zehlendorf/Haus-Pius-Vorderansicht-min.webp';
import zehlendorf2Img from './../../assets/images/zehlendorf/Haus-Pius-Hinteransicht-min.webp';
import zehlendorf3Img from './../../assets/images/zehlendorf/Küche-Haus-Pius-min.webp';
import zehlendorf4Img from './../../assets/images/zehlendorf/Therapieraum-mit-Baellebad-min.webp';

function Home() {


    return (
        <>
            <section className="page">
                <div className="home-page background-img" style={{
                    height: calcHeight(Constants.CALC_HEIGHT.TWO_BY_TWO_HEIGHT), // window - message form - navbar
                }}>
                </div>
                <div className="flex-container-two-two" style={{
                    height: calcHeight(Constants.CALC_HEIGHT.TWO_BY_TWO_HEIGHT), // window - message form - navbar
                }}>
                    <Link to={Constants.CHRONIK_ROUTE} className="tile tile-one" role="button">
                        <p className="label mobile">Über uns</p>
                        <div className="book normal desktop" aria-hidden="true">
                            <div className="back"></div>
                            <div className="page6">
                                <h1>Der Gründer der Stiftung</h1>
                                <p> Die Stiftung Haus Pius XII wurde durch Don Luigi 
                                    Fraccari (1909-2000) im Jahr 1949 gegründet.
                                    </p>
                                    <p>
                                    Der italienischstämmige Priester wurde 1909 in der 
                                    Nähe von Verona geboren. Nachdem er einige Jahre in 
                                    seiner Heimat als Seelsorger tätig gewesen war, 
                                    gaben der</p>
                            </div>
                            <div className="page5">
                            </div>
                            <div className="page4"></div>
                            <div className="page3"></div>
                            <div className="page2"></div>
                            <div className="page1"></div>
                            <div className="front">
                                <p className="text">ÜBER<br/>UNS</p>
                            </div>
                        </div>
                    </Link>
                    <Link to={Constants.IMPRESSIONS_ROUTE} className="tile tile-two" role="button">
                        <p className="label mobile">Impressionen</p>
                        <div className="book photo desktop" aria-hidden="true">
                            <div className="back"></div>
                            <div className="page6">
                                <div className="img-div">
                                    <img src={biesdorf1Img} alt="Platzhalterbilder für eine Animation"></img>
                                </div>
                                <div className="img-div">
                                    <img src={biesdorf2Img} alt="Platzhalterbilder für eine Animation"></img>
                                </div>
                                <div className="img-div">
                                    <img src={biesdorf3Img} alt="Platzhalterbilder für eine Animation"></img>
                                </div>
                                <div className="img-div">
                                    <img src={biesdorf4Img} alt="Platzhalterbilder für eine Animation"></img>
                                </div>
                            </div>
                            <div className="page5">
                                <div className="img-div">
                                    <img src={zehlendorf1Img} alt="Platzhalterbilder für eine Animation"></img>
                                </div>
                                <div className="img-div">
                                    <img src={zehlendorf2Img} alt="Platzhalterbilder für eine Animation"></img>
                                </div>
                                <div className="img-div">
                                    <img src={zehlendorf3Img} alt="Platzhalterbilder für eine Animation"></img>
                                </div>
                                <div className="img-div">
                                    <img src={zehlendorf4Img} alt="Platzhalterbilder für eine Animation"></img>
                                </div>
                            </div>
                            <div className="page4"></div>
                            <div className="page3"></div>
                            <div className="page2"></div>
                            <div className="page1"></div>
                            <div className="front">
                                <p className="text">IMPRESSIONEN</p>
                                <div className="input"></div>
                                <div className="rings"></div>
                                <div className="rings"></div>
                                <div className="rings"></div>
                                <div className="rings"></div>
                            </div>
                        </div>
                    </Link>
                    <Link to={Constants.OFFERS_ROUTE} className="tile tile-three" role="button">
                        <p className="label mobile">Freie Plätze</p>
                        <div className="table-and-chairs desktop" aria-hidden="true">
                            <div className="table">
                                <div className="wood-u"></div>
                                <div className="wood-dark2"></div>
                                <div className="wood-b"></div>
                                <div className="wood-dark"></div>
                                <div className="wood-u"></div>
                                <div className="wood-dark2"></div>
                                <div className="wood-b"></div>
                                <div className="wood-u"></div>
                                <div className="wood-dark2"></div>
                                <div className="wood-b"></div>
                                <div className="wood-dark"></div>
                                <div className="wood-u"></div>
                                <div className="wood-dark2"></div>
                                <div className="wood-b"></div>
                                <div className="tablecloth">
                                    <p>Freie Plätze</p>
                                </div>
                            </div>
                            <div className="chair">
                                <div className="backrest"></div>
                            </div>
                            <div className="chair">
                                <div className="backrest"></div>
                            </div>
                            <div className="chair">
                                <div className="backrest"></div>
                            </div>
                            <div className="chair">
                                <div className="backrest"></div>
                            </div>
                        </div>
                    </Link>
                    <Link to={Constants.SUPPORT_ROUTE}  className="tile tile-four" role="button">
                        <p className="label mobile">Unterstützung</p>
                        <div className="hand-and-heart desktop" aria-hidden="true">
                            <div className='heart'></div>
                            <div className='hand'>
                        
                                <div className='arm'></div>
                                <div className='palm'></div>
                                <div className='finger'></div>
                                <div className='finger'></div>
                                <div className='finger'></div>
                                <div className='finger'></div>
                                <div className='thumb'></div>
                                <div className='shirt'></div> 
                                <p>Unterstützung</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Home;