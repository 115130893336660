import React from 'react'

function Geocaching() {
  return (
    <div className="text-container font-size-15">
        <div className="middle">
            <h1>Geocaching</h1><br/>
            Geocaching ist eine moderne Form einer Schatzsuche bzw. Schnitzeljagd. Ausgestattet mit einem GPS-Empfänger und den Koordinaten eines Geocaches kann man den "Schatz" finden, den jemand anderes an einem ungewöhnlichen/ sehenswürdigen Platz versteckt hat.
            <br/><br/>
            Der Cache der Stiftung Haus Pius heißt „Don Luigi“ und wurde 2013 versteckt. 
            Er wurde schon über 100 Mal erfolgreich geöffnet.
            Des Weiteren sind noch mehrere Gedenkmünzen der Stiftung Haus Pius (Unikate) weltweit bei dieser Schnitzeljagd unterwegs.
            Wir wünschen allen Schatzsuchern viel Erfolg beim Suchen und Finden.
        </div>
    </div>
  )
}

export default Geocaching