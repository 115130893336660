import React from 'react'
import Scroller from '../scroller/Scroller'

function ScrollerPage() {
    return (
        <Scroller></Scroller>
    )
}

export default ScrollerPage
