import React from 'react'
import { Link } from 'react-router-dom';

import * as Constants from './../../../Constants';

function Impressum() {
    return (
        <>
            <p>Impressum</p>
            <div className="flex-container">
                <div className="contact-area">
                    <div className="contact-name">
                        Verantwortlich i.S.v. § 5 DDG:<br/><br/>
                    </div>
                    <div className="contact-information">
                        <p>
                            Stiftung Haus Pius XII<br/>
                            Sophie-Charlotte-Straße 31<br/>
                            D-14169 Berlin<br/><br/>
                        </p>
                        <p>
                            Tel.: <a href="tel:+49-30-81-81-85-23">+49 (0)30 81 81 85 - 23</a><br/>
                            E-Mail: <a href="mailto:geschaeftsfuehrung@stiftung-haus-pius.de?subject=Aus Homepage Kontakt">geschaeftsfuehrung@stiftung-haus-pius.de</a><br/>
                            Die Stiftung Haus Pius XII ist eine rechtsfähige Stiftung bürgerlichen Rechts.<br/> Sie wird vertreten durch den Vorstand Dr. Martin Korte.
                        </p>
                    </div>
                </div>  
                <div className="contact-area">
                    <div className="contact-name">
                        Bankverbindung:<br/><br/>
                    </div>
                    <div className="contact-information">
                        <p>
                            Bank für Sozialwirtschaft<br/><br/><br/>
                        </p>
                        <p>
                            BLZ: 100 205 00<br/>
                            Konto: 3124200<br/>
                            BIC: 370 205 00<br/>
                            IBAN: DE06 3702 0500 0003 1242 00
                        </p>
                    </div>
                </div>
                <div className="contact-area">
                    <div className="contact-name">
                        Online-Streitbeilegung:<br/><br/>
                    </div>
                    <div className="contact-information">
            
                        <p>
                            Die OS-Plattform erreichen Sie <a className="fancy-link" target="_blank" rel="noreferrer" href="http://ec.europa.eu/consumers/odr/">hier</a>.<br/><br/>
                        </p>
                        <p>
                            Disclaimer: <Link to={Constants.DISCLAIMER_ROUTE}>zur Haftung und Verantwortung</Link><br/>
                        </p>
                        <br/><br/>
                        <p><u>Webmaster:</u></p>
                        <p> Tobias Henning</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Impressum
