import React from 'react'
import Geschichte from '../../assets/downloads/Casa_Pius 2000.pdf'
import Leitbild from '../../assets/downloads/Leitbild Stiftung Haus Pius XII.pdf'
import Flyer from '../../assets/downloads/Flyer.pdf'

function ServiceDownload() {
  return (
    <>
        <div className='content'>
            <section style={{height: '100%'}} >
                <div className="text-container">
                    <div className="middle">
                        <h1>Service und Downloads</h1>
                            Im den unteren Bereichen finden Sie verschiedene zum Download bereitstehende Dokumente. <br/><br/>
                            Folgende Dokumente werden nur auf Anfrage herausgegeben:
                        <ul>
                            <li>Kurzfristige Unterbringung von Kindern in Krisensituationen</li>
                            <li>Satzung der Stiftung Haus Pius XII</li>
                            <li>Betriebserlaubnis für die Einrichtung</li>

                        </ul>
                    </div>
                </div>
            </section>           
        </div>
        <a className='content' href={Geschichte} target="_blank" rel="noreferrer"><p>Unsere Geschichte</p></a>
        <a className='content' href={Leitbild} target="_blank" rel="noreferrer"><p>Unser Leitbild</p></a>
        <a className='content' href={Flyer} target="_blank" rel="noreferrer"><p>Unser Flyer</p></a>
    </>
  )
}

export default ServiceDownload