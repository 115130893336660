import React from 'react'
import { useLocation } from 'react-router-dom'
import * as Constants from '../../Constants';
import {calcHeight} from './../../Utils'
import ServiceDownload from '../lists/ServiceDownload';


function ListContainer() {
    const location = useLocation();
    let components = [];
    let styleClass;
    let backgroundClass;

    switch (location.pathname) {
        case Constants.SERVICE_DOWNLOAD_ROUTE:
            components.push(<ServiceDownload key={1} />);
            styleClass = "service-download";
            backgroundClass = "zehlendorf-page";
            break;

        default: 
            break;
    }

    return (
        <>
        <div className={`background-img ${backgroundClass}`} style={{
            height: calcHeight(Constants.CALC_HEIGHT.SWITCH_HEIGHT), // window - message form - navbar
        }}>
        </div>
        <div className={`list-container ${styleClass}`} style={{
            height: calcHeight(Constants.CALC_HEIGHT.SWITCH_HEIGHT), // window - message form - navbar
        }} >
           
            {components.map((component) => {
                return (
                    component
                );
            })}
                
        </div>
        </>
    )
}

export default ListContainer