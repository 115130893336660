import React from 'react'

import '../../App.scss';
import './Scroller.scss';
import { useLocation } from 'react-router-dom'

import Footer from '../footer/Footer';
import variables from './../../assets/_variables.module.scss';
import Disclaimer from './scrollerContent/Disclaimer';
import * as Constants from './../../Constants'

function Scroller({content}) {
    const location = useLocation();
    let component;

    switch (location.pathname) {
        case Constants.DISCLAIMER_ROUTE:
            component = <Disclaimer/>;
            break;
        default: 
            break;
    }

    return (
        <>
            <section className="scroller-page ">
                <div className="background-img" style={{
                    height: (window.innerHeight - variables.navHeightWithoutPixels), // window - message form - navbar
                }}>
                </div>
                
                <div className="scroller-container" style={{
                    minHeight: (window.innerHeight - variables.navHeightWithoutPixels), // window - message form - navbar
                }}>
                    {(() => {
                        return (
                            component
                        )
                     })()}
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Scroller
