import React from 'react'
import '../../App.scss';
import Footer from '../footer/Footer';
import ListContainer from '../container/ListContainer';

function ListContainerPage() {
    return (
        <>
            <section className="page">
            
                <ListContainer/>
            </section>
            <Footer />
        </>
    )
}

export default ListContainerPage