import {DataHelper} from '../helper/dataHelper';


function Offers() {
  let date = new Date();
  let dateString = date.toLocaleString("de", { day : '2-digit'}) + ". " + date.toLocaleString("de", { month: "long" }) + " " + date.getFullYear();

  return (
    <div className="text-container font-size-15 offers">
    <div className="middle">
        <h1>Freie Plätze</h1><br/>
        <div className='flex-container'>
          <div>
            Als freier Träger der Kinder- und Jugendhilfe bietet die gemeinnützige Stiftung Haus Pius XII insgesamt 23 Kindern und Jugendlichen Aufnahme, Unterkunft und Heimat an. Die Einrichtungen befinden sich in drei großzügig modernisierten Häusern in den Berliner Stadtteilen Zehlendorf und Biesdorf. Dabei stehen unterschiedliche Wohnformen wie etwa Gruppenangebote mit Intensivleistung (A 3), ein familienanaloges Gruppenangebot (B 3) sowie zwei Verselbstständigungsplätze zur Verfügung. Eine Belegung ist über entsprechende Jugendämter möglich. Immer wieder werden auch Plätze in den Einrichtungen frei, die belegt werden können.        
          
          </div>
          <div className='counter'>
            <p className='title'>Aktuell freie Plätze:</p>
            <p className='date'>(Stand: {dateString})</p>
            <p className='amount'>{DataHelper.sFreeSpots}</p>

          </div>
        </div>
        </div>

</div>
  )
}

export default Offers