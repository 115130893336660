import React from 'react'
import ZehlendorfLocation from './../tiles/ZehlendorfLocation';
import { useLocation } from 'react-router-dom'
import * as Constants from '../../Constants';
import {calcHeight} from './../../Utils'
import BiesdorfLocation from './../tiles/BiesdorfLocation';
import ZehlendorfImpressions from './../tiles/ZehlendorfImpressions';
import BiesdorfImpressions from './../tiles/BiesdorfImpressions';
import ZehlendorfOffers from './../tiles/ZehlendorfOffers';
import BiesdorfOffers from './../tiles/BiesdorfOffers';
import FreiplatzApp from './../tiles/FreiplatzApp';
import JobOffers from './../tiles/JobOffers';
import Support from './../tiles/Support';
import Offers from './../tiles/Offers';
import Celebration from './../tiles/Celebration';
import Geocaching from '../tiles/Geocaching';

function SingleContainer() {
    const location = useLocation();
    let components = [];
    let styleClass;
    let backgroundClass;

    switch (location.pathname) {
        case Constants.ZEHLENDORF_LOCATION_ROUTE:
            components.push(<ZehlendorfLocation key={1} />);
            styleClass = "ZehlendorfLocation";
            backgroundClass = "zehlendorf-page";
            break;

        case Constants.ZEHLENDORF_IMPRESSIONS_ROUTE:
            components.push(<ZehlendorfImpressions key={1} />);
            styleClass = "ZehlendorfImpressions";
            backgroundClass = "zehlendorf-page";
            break;

        case Constants.ZEHLENDORF_OFFERS_ROUTE:
            components.push(<ZehlendorfOffers key={1} />);
            styleClass = "ZehlendorfOffers";
            backgroundClass = "zehlendorf-page";
            break;

        case Constants.BIESDORF_LOCATION_ROUTE:
            components.push(<BiesdorfLocation key={1} />);
            styleClass = "BiesdorfLocation";
            backgroundClass = "biesdorf-page";
            break;

        case Constants.BIESDORF_IMPRESSIONS_ROUTE:
            components.push(<BiesdorfImpressions key={1} />);
            styleClass = "BiesdorfImpressions";
            backgroundClass = "biesdorf-page";
            break;

        case Constants.BIESDORF_OFFERS_ROUTE:
            components.push(<BiesdorfOffers key={1} />);
            styleClass = "BiesdorfOffers";
            backgroundClass = "biesdorf-page";
            break;

        case Constants.IMPRESSIONS_ROUTE:
            components.push(<ZehlendorfImpressions key={1} />);
            components.push(<BiesdorfImpressions key={2} />);
            styleClass = "Impressions";
            backgroundClass = "home-page";
            break;

        case Constants.OFFERS_ROUTE:
            components.push(<Offers key={1} />);
            styleClass = "Offers";
            backgroundClass = "home-page";
            break;

        case Constants.CELEBRATION_ROUTE:
            components.push(<Celebration key={1} />);
            styleClass = "Celebration";
            backgroundClass = "home-page";
            break;

        case Constants.APP_ROUTE:
            components.push(<FreiplatzApp key={1} />);
            styleClass = "FreiplatzApp";
            backgroundClass = "home-page";
            break;

        case Constants.JOB_OFFERS_ROUTE:
            components.push(<JobOffers key={1} />);
            styleClass = "JobOffers";
            backgroundClass = "home-page";
            break;

        case Constants.SUPPORT_ROUTE:
            components.push(<Support key={1} />);
            styleClass = "Support";
            backgroundClass = "home-page";
            break;
            
        case Constants.GEOCACHING_ROUTE:
            components.push(<Geocaching key={1} />);
            styleClass = "Geocaching";
            backgroundClass = "home-page";
            break;
            
        default: 
            break;
    }

    return (
        <>
        <div className={`background-img ${backgroundClass}`} style={{
            height: calcHeight(Constants.CALC_HEIGHT.SWITCH_HEIGHT), // window - message form - navbar
        }}>
        </div>
        <div className="single-container" style={{
            height: calcHeight(Constants.CALC_HEIGHT.SWITCH_HEIGHT), // window - message form - navbar
        }} >
            <div tabIndex="0" className={`content ${styleClass}`}>
                <section style={{height: '100%'}} >
                    {components.map((component) => {
                        return (
                            component
                        );
                    })}
                </section>
            </div>
        </div>
        </>
    )
}

export default SingleContainer
