import React from 'react'
import './Container.scss';
import { useLocation } from 'react-router-dom'
import * as Constants from '../../Constants';
import Foundation from '../tiles/Foundation';
import Founder from '../tiles/Founder';
import Goals from '../tiles/Goals';
import $ from 'jquery';
import {calcHeight} from '../../Utils'
import Support from '../tiles/Support';
import variables from '../../assets/_variables.module.scss';

function SwitchContainer() {
    const location = useLocation();
    const switchesText = [];
    const components = [];

    const handleSwitchClick = (e) =>{
        if(e.type === "keypress" && e.charCode !== 13){
            return;
        }

        let index = $(e.target).attr('index');
        $(".content-js").removeClass('active');
        $('.content-js[index="'+index+'"]').addClass('active');
        $(".switch-js").removeClass('active');
        $('.switch-js[index="'+index+'"]').addClass('active');

    }

    switch (location.pathname) {
        case Constants.CHRONIK_ROUTE:
            switchesText.push("Der Gründer der Stiftung");
            switchesText.push("Die Stiftung Haus Pius XII");
            switchesText.push("Unsere Ziele");
            switchesText.push("Unterstützung");
            components.push(<Foundation />);
            components.push(<Founder />);
            components.push(<Goals />);
            components.push(<Support />);
            break;
        default: 
            break;
    }

    let calcedHeight = calcHeight(Constants.CALC_HEIGHT.SWITCH_HEIGHT);
    return (
        <div className={`switch-container four ${calcedHeight <= variables.fourContainerCollapseHeight ? "small" : ""}`} style={{
            height: calcedHeight, // window - message form - navbar
        }} >
            <div className="shadow"></div>
            {switchesText.map((text, i) => {
                return (
                    <p onClick={handleSwitchClick} role="button" onKeyPress={handleSwitchClick} tabIndex="0" key={i} index={i} className={`switch switch-js  ${i === 0 ? "active" : ""}`}>
                        {text}
                    </p>
                );
            })}
            {components.map((component, i) => {
                return (
                    <div key={i} index={i} tabIndex="0" className={`content content-js ${i === 0 ? "active" : ""}`}>{component}</div>
                );
            })}
        </div>
    )
}

export default SwitchContainer
