import React from 'react'

import Don_Luigi_Fraccari from './../../assets/images/Don_Luigi_Fraccari.webp';

function Foundation() {
    

    return (
        <div className="text-container font-size-15">
            <div className="middle">
                <h1>Der Gründer der Stiftung</h1><br/>
                <img className="image right image-size-12" src={Don_Luigi_Fraccari} alt="Don Luigi Fraccari"/>
                Die Stiftung Haus Pius XII wurde durch Don Luigi Fraccari (1909-2000) im Jahr 1949 gegründet. Der italienischstämmige Priester wurde 1909 in der Nähe von Verona geboren. Nachdem er einige Jahre in seiner Heimat als Seelsorger tätig gewesen war, gaben der Krieg und seine Folgen dem Leben Fraccaris eine neue Orientierung.
                <br/><br/>
                Als Italien sich nach der Entmachtung Mussolinis im Kriegsjahr 1943 von der "Achse" löste und sich den Alliierten zuwandte, fasste Fraccari den Entschluss, nach Deutschland zu gehen und sich hier um das Schicksal seiner Landsleute zu kümmern.
                <br/><br/>
                Im Laufe seiner Arbeit hatte Fraccari die Idee entwickelt, ein Zufluchtshaus für bedürftige italienische Waisenkinder, für Alte und Alleinstehende zu gründen. Da es ihm für das Vorhaben am nötigen Geld fehlte, wandte er sich an Papst Pius XII. und erhielt in einer Privataudienz von diesem das nötige finanzielle Startkapital für sein Vorhaben. In einer Villa in der Sophie Charlotte Straße in Berlin-Zehlendorf fand Fraccari das geeignete Haus für sein Projekt, „Casa Pio XII.". Im Dezember 1959 wurde die Stiftung um das Nachbargrundstück mit einem Einfamilienhaus erweitert. Im Jahr 2017 kam dann schließlich eine weitere stationäre Einrichtung für Kinder und Jugendliche im Berliner Stadtteil Biesdorf hinzu.
                <br/><br/>
                Fraccari kümmerte sich aber auch um Verstorbene. So sorgte er dafür, dass verstreut begrabene Kriegsgefallene, Internierte und von den Nazis ermordete Zwangsarbeiter 1953 in den  Zehlendorfer Waldfriedhof umgebettet wurden.
                <br/><br/>
                Für seine engagierte und couragierte Arbeit in Deutschland erhielt Don Luigi Fraccari zahlreiche Auszeichnungen. 1957 verlieh ihm der italienische Staatspräsident den Stern der Solidarität. 1965 wurde er in Italien zum Ritter der Ehrenlegion ernannt, von Papst Pius XII. wurde ihm der Titel Monsignore verliehen. Im Jahr 1974 erhielt Don Luigi Fraccari für sein Wirken um die deutsch-italienische Verständigung gemeinsam 
                mit Prof. Carlo Schmid den de Gasperi Preis.  
            </div>
        </div>
    )
}

export default Foundation
