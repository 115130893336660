import React from 'react'
import Todesanzeige_Fraccari from './../../assets/images/Todesanzeige_Fraccari.webp';

function Celebration() {
    return (
    <div className="text-container font-size-15 celebration">
        <div className="middle">
            <h1>75 Jahre Stiftung Haus Pius XII</h1><br/>
                <div className='flex-container'>
                    <div>
                        In diesen Tagen jähren sich Geburtstag und Todestag des Gründers der Stiftung Haus Pius XII. 
                        Monsignore Don Luigi Fraccari wurde am 24.01.1909 geboren und verstarb in seiner Heimat in Italien am 24.01.2000.
                        <br/>
                        <br/>
                        Monsignore Fraccari ermöglichte 1949 die Gründung der Stiftung Haus Pius XII in Berlin-Zehlendorf.
                        Stiftungszweck  war und ist es, "hilfesuchenden Menschen Pflege und Unterkunft zu gewähren" (Text der Satzung von 1949).
                        Heute begleiten und betreuen die Mitarbeiterinnen und Mitarbeiter der Stiftung Kinder und Jugendliche nach dem Kinder- und Jugendhilfegesetz in mehreren Einrichtungen in Berlin.
                        <br/>
                        <br/>
                        Übrigens, in diesem Jahr 2024 begeht die Stiftung Haus Pius XII ihr 75-jähriges Jubiläum; dies wird mit einem entsprechenden Fest gefeiert.
                    </div>
                    <img className="image image-size-12" src={Todesanzeige_Fraccari} alt="Bild von Fraccari"/>
                </div>
           
        </div>
    </div>
    )
}

export default Celebration
