import React from 'react'

function Support() {
    return (
        <div className="text-container font-size-15 support">
            <div className="middle">
                <h1>Unterstützung</h1><br/>
                <div className='flex-container'>
                    <div>
                        Eine Unterstützung unserer Arbeit ist vielfältig möglich. 
                        <br/><br/>
                        Dies kann z. B. durch einen ehrenamtlichen Einsatz direkt in unseren Einrichtungen in Berlin-Biesdorf oder Berlin-Zehlendorf erfolgen. 
                        <br/><br/>
                        Natürlich freut sich die Stiftung Haus Pius XII aber auch über eine   
                        <a className="fancy-link" href="https://secure.spendenbank.de/form/988/" target='_blank' rel="noreferrer"> finanzielle Unterstützung</a>. 
                    </div>
                    <a className="button" href="https://secure.spendenbank.de/form/988/" target='_blank' rel="noreferrer" >
                        <div>Hier<br/>Spenden</div>
                    </a>
                </div>
            </div>
        
        </div>
    )
}

export default Support
