import React from 'react'
import $ from 'jquery';

export class DataHelper extends React.Component {
    static aData;
    static aJobs = [];
    static sFreeSpots;

    static async loadSpreadsheet() {
        let that = this;
        let url = "https://docs.google.com/spreadsheets/d/e/2PACX-1vS3xajrAXqib2qmdb33C3xxXlBBo4UnjzyyNXWnlI2VFvtgoABusGtvF7C-BD-Pam9egWJgneAxoENd/pub?gid=0&single=true&output=tsv"
        return $.ajax(url).done(function(result){
            let arr = result.split('\n'); 
            var jsonObj = [];
            var headers = arr[0].split('\t');
            for(var i = 1; i < arr.length; i++) {
                var data = arr[i].split('\t');
                var obj = {};
                for(var j = 0; j < data.length; j++) {
                if(headers[j] != null){
                    obj[headers[j].trim()] = data[j].trim();
                }
                }
                jsonObj.push(obj);
            }
            JSON.stringify(jsonObj);

            jsonObj.forEach(element => {
                switch(element.Typ){
                    case "Plätze":
                        that.sFreeSpots = element.Text;
                        break;
                    case "Stellenausschreibung":
                        that.aJobs.push(element);
                        break;
                    default: break;
                }
            });
        });
    }
}