export const CHRONIK_ROUTE = "/chronik";
export const DISCLAIMER_ROUTE = "/disclaimer";
export const IMPRESSIONS_ROUTE = "/impressions";
export const OFFERS_ROUTE = "/offers";
export const APP_ROUTE = "/app";
export const SERVICE_DOWNLOAD_ROUTE = "/service";
export const CELEBRATION_ROUTE = "/celebration";

export const JOB_OFFERS_ROUTE = "/jobs";
export const SUPPORT_ROUTE = "/support";
export const GEOCACHING_ROUTE = "/geocaching";


//Zehlendorf
export const ZEHLENDORF_LOCATION_ROUTE = "/zehlendorf/location";
export const ZEHLENDORF_IMPRESSIONS_ROUTE = "/zehlendorf/impressions";
export const ZEHLENDORF_OFFERS_ROUTE = "/zehlendorf/offers";

//Biesdorf
export const BIESDORF_LOCATION_ROUTE = "/biesdorf/location";
export const BIESDORF_IMPRESSIONS_ROUTE = "/biesdorf/impressions";
export const BIESDORF_OFFERS_ROUTE = "/biesdorf/offers";


//for HeightCalculation
export enum CALC_HEIGHT{
    SWITCH_HEIGHT,
    TWO_BY_TWO_HEIGHT
};


export class marketingEnabled {
    _value = false;
    // access modifier
    get value() {
        return this._value;
    }
    set value(value) {
        this._value = value;
    }
}