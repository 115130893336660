import React, { useState } from 'react'
import Haus_Biesdorf_Hinteransicht from './../../assets/images/biesdorf/Haus-Biesdorf-Hinteransicht.webp';
import './Tiles.scss';

let checkedForLocal = false;

function BiesdorfLocation() {
    const [hasMarketing, setHasMarketing] = useState(false);
    const handleCookieClick = () => window.Cookiebot.renew();
  
    function CookibotOnLoad(e) {
        setHasMarketing(e.target.Cookiebot.consent.marketing);
    }

    //debug purpose
    if(window.location.hostname === "localhost"){
        if(!checkedForLocal){
            setHasMarketing(true)
            checkedForLocal = true;
        }
    }else if(window.Cookiebot !== undefined && window.Cookiebot.consent !== undefined && window.Cookiebot.consent.marketing !== undefined && hasMarketing !== window.Cookiebot.consent.marketing){
        setHasMarketing(window.Cookiebot.consent.marketing);
    }

    window.removeEventListener('CookiebotOnLoad',CookibotOnLoad);
    window.addEventListener('CookiebotOnLoad', CookibotOnLoad, false);


    let embedding;

    if (hasMarketing) {
        embedding = <iframe title="Biesdorf" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2428.730378195262!2d13.564522616109333!3d52.49587724576853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a8490e154afb6d%3A0x80048b21603c580a!2sAm%20Binsengrund%2020%2C%2012683%20Berlin!5e0!3m2!1sde!2sde!4v1641299767176!5m2!1sde!2sde" width="800" height="600" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>;
      } else {
        embedding = <div className="cookie-disabled">Erlaube <span className='fancy-link' onClick={handleCookieClick}>Marketing Cookies</span> um auf Google-Maps zugreifen zu können</div>;
    }

    return (
        <div className="text-container font-size-15 biesdorf-location">
            <div className="middle">
            <h1>Berlin-Biesdorf – Unsere Einrichtung</h1><br/>
            
          
            <div className='flex-container'>
                <div>
                    In Berlin-Biesdorf stehen mehrere geräumige und z. T. behindertengerechte Zimmer in einem großen Einfamilienhaus, im Haus Biesdorf, für insgesamt 8 Kinder und Jugendliche zur Verfügung.
                    <br/><br/>
                    Ebenfalls zur Einrichtung gehört ein großer Garten mit unterschiedlichen Spielgeräten sowie ein Mehrzweckplatz. Für die Mobilität stehen ein Kleinbus sowie ein PKW zur Verfügung, um damit Fahrdienste und Ausflüge an den Wochenenden oder in den Ferien zu ermöglichen.
                    <br/><br/>
                    Im unmittelbaren Sozialraum befinden sich Kindertagesstätten, Schulen aller Schularten sowie Freizeiteinrichtungen. Die Einrichtung ist gut mit öffentlichen Verkehrsmitteln erreichbar.
                </div>
                <img className="image image-size-16" src={Haus_Biesdorf_Hinteransicht} alt="Hinteransicht des Hauses Biesdorf"/>
            </div>
            
            <div className="map-responsive">
                {embedding}
            </div>
            </div>
        </div>
    )
}

export default BiesdorfLocation
