import React from 'react'
import Bueste from './../../assets/images/bueste.webp';

function Founder() {
    return (
    <div className="text-container font-size-15">
        <div className="middle">
            <h1>Die Stiftung Haus Pius XII</h1><br/>
            <img className="image right image-size-12" src={Bueste} alt="Büste"/>
            Als stationäre Einrichtung steht die gemeinnützige Stiftung Haus Pius XII seit mehr als 70 Jahren im Dienst der Kinder- und Jugendhilfe und unterstützt durch ihre Arbeit als kompetenter Partner Kinder und Jugendliche aus Berlin sowie dem näheren und weiteren Umland. Die Stiftung Haus Pius XII ist Mitglied im Caritasverband für das Erzbistum Berlin e.V. und bietet entsprechend der gesetzlichen Grundlagen insgesamt 23 Wohnplätze in drei großzügig modernisierten Häusern mit großem Garten und Freizeitangeboten in den Stadtteilen Zehlendorf und Biesdorf in Berlin. Die überschaubare Größe der Einrichtungen ermöglicht die Entwicklung und Gestaltung passgenauer Hilfen für die Kinder und Jugendlichen.
            <br/>
            <br/>
            Ein qualifiziertes Team mit einer innewohnenden Fachkraft sichert die Qualität der Betreuung und der integrierten pädagogischen Angebote. Gemeinsam mit den Kindern und Jugendlichen entwickeln die MitarbeiterInnen der Stiftung weiterführende Perspektiven und begleiten auch in schulischen und beruflichen Fragen. Eine vernetzte und sozialraumorientierte Kooperation mit Partnern vor Ort wie beispielsweise Kindertagesstätten, Schulen, Ausbildungsbetrieben, Ärzten oder Therapeuten ist ein wesentlicher Bestandteil der Arbeit und eröffnet flexible und individuelle Unterstützungsmöglichkeiten.
            <br/>
            <br/>
            Neben Einsatzmöglichkeiten für Fachkräfte stellen wir auch Praktikumsplätze und Plätze im Bundesfreiwilligendienst zur Verfügung.
            <br/>
            <br/>
            Vor dem Hintergrund eines familienorientierten Hilfeangebots mit integrativem Arbeitsansatz bietet die Stiftung Plätze in unterschiedlichen Wohnformen für Kinder und Jugendliche aus Berlin und dem gesamten Bundesgebiet.
            <br/>
            <br/>
            Hintergründe zu der Stiftung und unsere bisherige Homepage erreichen Sie     
            <a
              className="fancy-link" 
              href="http://www.stiftung-haus-pius.org/"
              target='_blank'
              rel="noreferrer"
            > hier</a>.
        </div>
    </div>
    )
}

export default Founder
