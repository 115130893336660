import React from 'react';
import ReactDOM from 'react-dom';
import RouterClass from './RouterClass';


ReactDOM.render(
    <RouterClass />,document.getElementById('root')
);


