import React from 'react'
import '../../App.scss';
import Footer from '../footer/Footer';
import SingleContainer from '../container/SingleContainer';

function SingleContainerPage() {
    return (
        <>
            <section className="page">
            
                <SingleContainer/>
            </section>
            <Footer />
        </>
    )
}

export default SingleContainerPage
