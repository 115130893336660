import React, {useState}  from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';
import FooterOverlay from './FooterOverlay';
import * as Constants from './../../Constants';
import Geocaching from './../../assets/images/Geocaching.webp';
import $ from 'jquery';


function Footer() {
  const [activeContactOverlay, setActiveContactOverlay] = useState(false);
  const handleContactClick = (e) => {
    if(e.type === "keypress" && e.charCode !== 13){
      return;
    }
    setActiveContactOverlay(!activeContactOverlay);
    setTimeout(() => $(".footer-overlay-container .fas.fa-times")[0].focus(), 300)
    
  }

  const [activeImpressumOverlay, setActiveImpressumOverlay] = useState(false);
  const handleImpressumClick = (e) => {
    if(e.type === "keypress" && e.charCode !== 13){
      return;
    }
    setActiveImpressumOverlay(!activeImpressumOverlay);
    setTimeout(() => $(".footer-overlay-container .fas.fa-times")[1].focus(), 300)
  }

  const handleCookieClick = (e) => {
    if(e.type === "keypress" && e.charCode !== 13){
      return;
    }
    window.Cookiebot.show();
  }

  return (
    <div className='footer-container'>
      <FooterOverlay active={activeContactOverlay} setActive={setActiveContactOverlay} content="contact"/>
      <FooterOverlay active={activeImpressumOverlay} setActive={setActiveImpressumOverlay} content="impressum"/>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Sitemap</h2>
            <p className="fancy-link contact" tabIndex="0" onClick={handleContactClick} onKeyPress={handleContactClick} role="button">Kontakt</p>
            <p className="fancy-link impressum" tabIndex="0" onClick={handleImpressumClick} onKeyPress={handleImpressumClick} role="button">Impressum</p>
            <p className="fancy-link" tabIndex="0" onClick={handleCookieClick} onKeyPress={handleCookieClick} role="button">Cookie-Einwilligung</p>
            <Link className="fancy-link" to={Constants.DISCLAIMER_ROUTE}>Datenschutzerklärung</Link>
            <small className='website-rights' aria-hidden="true">Stiftung Haus Pius XII © {new Date().getFullYear()}</small>

          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <Link
              className="fancy-link" to={Constants.GEOCACHING_ROUTE}
            >
                Geocaching
            </Link>
            <a
              className="fancy-link" 
              href="https://www.facebook.com/Stiftung-Haus-Pius-XII-107325649351373/"
              target='_blank'
              rel="noreferrer"
            >
                Facebook
            </a>
            <a 
              className="fancy-link"
              href="https://twitter.com/SHP_Berlin"
              target='_blank'
              rel="noreferrer"
            >
                Twitter
            </a>
            <a 
              className="fancy-link"
              href="https://stiftunghauspius.wordpress.com/"
              target='_blank'
              rel="noreferrer"
            >
                Blog
            </a>
            <div className='social-icons'>
            <Link to={Constants.GEOCACHING_ROUTE} aria-label="Geocaching Seite"><img src={ Geocaching } alt="Geocaching Seite"/></Link>
            <a
              className='social-icon-link facebook'
              href="https://www.facebook.com/Stiftung-Haus-Pius-XII-107325649351373/"
              target='_blank'
              aria-label='Facebook'
              rel="noreferrer"
            >
              <i className='fab fa-facebook-f' />
            </a>
            <a
              className='social-icon-link twitter'
              href="https://twitter.com/SHP_Berlin"
              target='_blank'
              aria-label='Twitter'
              rel="noreferrer"
            >
              <i className='fab fa-twitter' />
            </a>
            <a
              className='social-icon-link twitter'
              href="https://stiftunghauspius.wordpress.com/"
              target='_blank'
              aria-label='Blog'
              rel="noreferrer"
            >
              <i className='fas fa-blog' />
            </a>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
