import './App.scss';
import './Loader.scss';
import React, {/* useState, */ useEffect} from 'react'
import Navbar from './components/navbar/Navbar';
import {Switch, Route} from 'react-router-dom';
import Home from './components/pages/Home';
import SwitchContainerPage from './components/pages/SwitchContainerPage';
import * as Constants from './Constants';
import ScrollerPage from './components/pages/ScrollerPage';
import ScrollToTop from './components/hooks/ScrollToTop';
import SingleContainerPage from './components/pages/SingleContainerPage';
import ListContainerPage from './components/pages/ListContainerPage';
import {DataHelper} from './components/helper/dataHelper';
//import { useLocation } from 'react-router-dom'
import piusImg from './assets/images/pius-title.webp';


function App() {
  const [dataLoaded, setDataLoaded] = React.useState(false);
  // const location = useLocation();
  // let reload = false;

  //const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const globalImages = importAll(require.context('./assets/images', false, /\.(png|PNG|jpe?g|JPG|svg)$/));
      const zehlendorfImages = importAll(require.context('./assets/images/zehlendorf', false, /\.(png|PNG|jpe?g|JPG|svg)$/));
      const biesdorfImages = importAll(require.context('./assets/images/biesdorf', false, /\.(png|PNG|jpe?g|JPG|svg)$/));
      cacheImages(globalImages);
      cacheImages(zehlendorfImages);
      cacheImages(biesdorfImages);
      await DataHelper.loadSpreadsheet();

      setDataLoaded(true);
    };

    fetchData();
  }, []);

  function importAll(r) {
    return r.keys().map(r);
  }

  // window.addEventListener('CookiebotOnDialogInit', (e) => {
  //   reload = true;
  //   Constants.marketingEnabled.value = e.target.Cookiebot.consent.marketing;
  //   console.log("CookiebotOnDialogInit");
  // }, false);
  
  // window.addEventListener('CookiebotOnLoad', (e) => {
  //   console.log(reload, e.target.Cookiebot.consent.marketing !== Constants.marketingEnabled.value);

  //   if(e.target.Cookiebot.consent.marketing === Constants.marketingEnabled.value){
  //     reload = false;
  //   }
  //   Constants.marketingEnabled.value = e.target.Cookiebot.consent.marketing;
  //   console.log(reload, Constants.marketingEnabled.value, e.target.Cookiebot.consent.marketing);
  //   if(reload && (location.pathname === Constants.ZEHLENDORF_LOCATION_ROUTE || location.pathname === Constants.BIESDORF_LOCATION_ROUTE)){
  //     window.location.reload(false);
  //   }
  // }, false);

  const cacheImages = async (srcArray) =>{
    const promises = await srcArray.map((src) => {
      const img = new Image();
      img.src = src.default;
      window[src] = img;
      return true;
    });

    await Promise.all(promises);
  };
  return (
    <>
      {dataLoaded ? (
        <>
          <Navbar/>
          <ScrollToTop>
            <Switch>
              <Route path='/' exact component={Home}/>
              <Route path={Constants.CHRONIK_ROUTE} exact component={SwitchContainerPage}/>
              <Route path={Constants.DISCLAIMER_ROUTE} exact component={ScrollerPage}/>
              <Route path={Constants.IMPRESSIONS_ROUTE} exact component={props => <SingleContainerPage {...props} />}/>
              <Route path={Constants.OFFERS_ROUTE} exact component={props => <SingleContainerPage {...props} />}/>
              <Route path={Constants.APP_ROUTE} exact component={props => <SingleContainerPage {...props} />}/>
              <Route path={Constants.SERVICE_DOWNLOAD_ROUTE} exact component={props => <ListContainerPage {...props} />}/>
              <Route path={Constants.JOB_OFFERS_ROUTE} exact component={props => <SingleContainerPage {...props} />}/>
              <Route path={Constants.SUPPORT_ROUTE} exact component={props => <SingleContainerPage {...props} />}/>
              <Route path={Constants.GEOCACHING_ROUTE} exact component={props => <SingleContainerPage {...props} />}/>
              <Route path={Constants.CELEBRATION_ROUTE} exact component={props => <SingleContainerPage {...props} />}/>

              {/* Zehlendorf */}
              <Route path={Constants.ZEHLENDORF_LOCATION_ROUTE} exact component={props => <SingleContainerPage {...props} />} />
              <Route path={Constants.ZEHLENDORF_IMPRESSIONS_ROUTE} exact component={props => <SingleContainerPage {...props} />}/>
              <Route path={Constants.ZEHLENDORF_OFFERS_ROUTE} exact component={props => <SingleContainerPage {...props} />}/>

              {/* Biesdorf */}
              <Route path={Constants.BIESDORF_LOCATION_ROUTE} exact component={props => <SingleContainerPage {...props} />}/>
              <Route path={Constants.BIESDORF_IMPRESSIONS_ROUTE} exact component={props => <SingleContainerPage {...props} />}/>
              <Route path={Constants.BIESDORF_OFFERS_ROUTE} exact component={props => <SingleContainerPage {...props} />}/>

              <script id="CookieDeclaration" src="https://consent.cookiebot.com/df08463a-c7fa-4b0b-8b64-244c444a19ec/cd.js" type="text/javascript" async></script>
            </Switch>
          </ScrollToTop>
      </>
    ) : (
      // Render a loading indicator or any other component while data is loading
      <div className='loading-holder'>
        <img src={piusImg} alt="Logo der Stiftung Haus Pius XII"/>
        <div className="sk-circle">
          <div className="sk-circle1 sk-child"></div>
          <div className="sk-circle2 sk-child"></div>
          <div className="sk-circle3 sk-child"></div>
          <div className="sk-circle4 sk-child"></div>
          <div className="sk-circle5 sk-child"></div>
          <div className="sk-circle6 sk-child"></div>
          <div className="sk-circle7 sk-child"></div>
          <div className="sk-circle8 sk-child"></div>
          <div className="sk-circle9 sk-child"></div>
          <div className="sk-circle10 sk-child"></div>
          <div className="sk-circle11 sk-child"></div>
          <div className="sk-circle12 sk-child"></div>
        </div>
      </div>
    )}
    </>
  );
}

export default App;
