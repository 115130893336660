import React, { useState, useCallback } from 'react'

import ImageViewer from 'react-simple-image-viewer';

import Esszimmer_Haus_Biesdorf from './../../assets/images/biesdorf/Esszimmer-Haus-Biesdorf.webp';
import Freizeitbereich_1_Haus_Biesdorf from './../../assets/images/biesdorf/Freizeitbereich-1-Haus-Biesdorf.webp';
import Freizeitbereich_2_Haus_Biesdorf from './../../assets/images/biesdorf/Freizeitbereich-2-Haus-Biesdorf.webp';
import Haus_Biesdorf from './../../assets/images/biesdorf/Haus-Biesdorf.webp';
import Haus_Biesdorf_Hinteransicht from './../../assets/images/biesdorf/Haus-Biesdorf-Hinteransicht.webp';
import Haus_Biesdorf_Vorderansicht from './../../assets/images/biesdorf/Haus-Biesdorf-Vorderansicht.webp';
import Küche_Haus_Biesdorf from './../../assets/images/biesdorf/Küche-Haus-Biesdorf.webp';
import Zimmer_1_Haus_Biesdorf from './../../assets/images/biesdorf/Zimmer-1-Haus-Biesdorf.webp';
import Zimmer_2_Haus_Biesdorf from './../../assets/images/biesdorf/Zimmer-2-Haus-Biesdorf.webp';
import Zimmer_3_Haus_Biesdorf from './../../assets/images/biesdorf/Zimmer-3-Haus-Biesdorf.webp';
import Zimmer_4_Haus_Biesdorf from './../../assets/images/biesdorf/Zimmer-4-Haus-Biesdorf.webp';

function BiesdorfImpressions() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
      Esszimmer_Haus_Biesdorf,
      Freizeitbereich_1_Haus_Biesdorf,
      Freizeitbereich_2_Haus_Biesdorf,
      Haus_Biesdorf,
      Haus_Biesdorf_Vorderansicht,
      Haus_Biesdorf_Hinteransicht,
      Küche_Haus_Biesdorf,
      Zimmer_1_Haus_Biesdorf,
      Zimmer_2_Haus_Biesdorf,
      Zimmer_3_Haus_Biesdorf,
      Zimmer_4_Haus_Biesdorf
    ];

    const text = [
      "Esszimmer im Haus",
      "Freizeitbereich im Haus",
      "Freizeitbereich im Haus",
      "Vorderansicht des Hauses",
      "Vorderansicht des Hauses",
      "Hinteransicht des Hauses",
      "Küche im Haus",
      "Einzelzimmer im Haus",
      "Doppelzimmer im Haus",
      "Spielecke im Haus",
      "Einzelzimmer im Haus",
    ];
  
    const openImageViewer = useCallback((e,index) => {
      if(e.type === "keypress" && e.charCode !== 13){
        return;
      }

      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);
  
    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };

    return (
      <div className="text-container">
        <h1>Biesdorf Impressionen</h1>
        <div className="impression-wrapper">
          {images.map((src, index) => (
            <div key={ index } className="img-element" tabIndex="0" onClick={ (e) => openImageViewer(e,index) } onKeyPress={ (e) => openImageViewer(e,index) }> 
              <div className="img-loader">
                <img
                  src={ src }
                  alt={text[index] + " in Berlin Biesdorf"}
                />
              </div>
              <p aria-hidden='true'>{text[index]}</p>
            </div>
          ))}
        

          {isViewerOpen && (
            <ImageViewer
              src={ images }
              currentIndex={ currentImage }
              disableScroll={ true }
              closeOnClickOutside={ true }
              onClose={ closeImageViewer }
            />
          )}
        </div>
      </div>
    )
}

export default BiesdorfImpressions
