import React from 'react'

function Goals() {
    return (
        <div className="text-container font-size-15">
            <div className="middle">
                <h1>Unsere Ziele</h1><br/>
                Schwerpunkt der pädagogischen Arbeit ist die ganzheitliche, systemische und ressourcenorientiert Förderung des jeweiligen Kindes bzw. Jugendlichen auf der Grundlage eines einzelfallorientierten Case-Managements und Betreuungssettings.
                <br/>
                <br/>
                Die angestrebten pädagogischen Ziele orientieren sich dabei stets am jeweiligen Entwicklungsstand und den Kompetenzen des Kindes bzw. des Jugendlichen und werden in einem individuellen Hilfeplan prozessorientiert fest- und fortgeschrieben.
                <br/>
                <br/>
                Durch eine persönliche, intensive und liebevolle Betreuungsarbeit bieten die MitarbeiterInnen den betreuten Kindern und Jugendlichen bestmögliche Entwicklungschancen für ihre Zukunft. Dabei gilt das pädagogische Engagement sowohl der Entwicklung jedes einzelnen Kindes und Jugendlichen wie auch dessen Integration in die Gemeinschaft der Wohngruppe. Grundlage dafür ist eine verbindliche 'Hausordnung', die unter Beteiligung der Kinder und Jugendlichen durch Gruppenregeln ergänzt wird.
                <br/>
                <br/>
                Eckpunkte dieser Gruppenarbeit sind gemeinsame Aktivitäten und regelmäßige Gruppengespräche (Kinderteams), deren Teilnahme für die Kinder und Jugendlichen verbindlich ist.
            </div>
    </div>
    )
}

export default Goals
