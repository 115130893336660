import React from 'react'
import './FooterOverlay.scss';
import Contact from './overlayContent/Contact';
import Impressum from './overlayContent/Impressum';
import $ from 'jquery';

 function FooterOverlay ({active = false, setActive, content}) { 
    const handleCloseClick = (e) => {
        if(e.type === "keypress" && e.charCode !== 13){
          return;
        }

        if (content === 'contact') {
            setTimeout(() => $(".footer-link-items .fancy-link.contact").focus(), 300)
        } else if (content === 'impressum') {
            setTimeout(() => $(".footer-link-items .fancy-link.impressum").focus(), 300)
        }
        setActive(false)
    }

    return (
        <div className={`footer-overlay-container ${active ? "active" : ""}`}>
            <i className='fas fa-times' tabIndex="0" onClick={handleCloseClick} onKeyPress={handleCloseClick}/>
            {(() => {
                    if (content === 'contact') {
                    return (
                        <Contact></Contact>
                    )
                    } else if (content === 'impressum') {
                    return (
                        <Impressum></Impressum>
                    )
                } 
            })()}
        </div>
    )
}
export default FooterOverlay;
