import React from 'react';
import '../../App.scss';
import Footer from '../footer/Footer';
import {calcHeight} from '../../Utils'
import SwitchContainer from '../container/SwitchContainer';
import * as Constants from '../../Constants';

function SwitchContainerPage() {
    return (
        <>
            <section className="page">
                <div className="home-page background-img" style={{
                    height: calcHeight(Constants.CALC_HEIGHT.SWITCH_HEIGHT), // window - message form - navbar
                }}>
                </div>
                <SwitchContainer />
            </section>
            <Footer />
        </>
    )
}

export default SwitchContainerPage
