import React, { useState, useCallback } from 'react'
import ImageViewer from 'react-simple-image-viewer';

import Appartement from './../../assets/images/zehlendorf/Appartement.webp';
import Garten_mit_Freizeitbereich from './../../assets/images/zehlendorf/Garten-mit-Freizeitbereich.webp';
import Haus_Giovanni from './../../assets/images/zehlendorf/Haus-Giovanni.webp';
import Haus_Giovanni_Seitenansicht from './../../assets/images/zehlendorf/Haus-Giovanni-Seitenansicht.webp';
import Haus_Pius_Hinteransicht from './../../assets/images/zehlendorf/Haus-Pius-Hinteransicht.webp';
import Haus_Pius_Vorderansicht from './../../assets/images/zehlendorf/Haus-Pius-Vorderansicht.webp';
import Küche_Haus_Pius  from './../../assets/images/zehlendorf/Küche-Haus-Pius.webp';
import Therapieraum_mit_Baellebad from './../../assets/images/zehlendorf/Therapieraum-mit-Baellebad.webp';
import Zimmer_2 from './../../assets/images/zehlendorf/Zimmer-2.webp';
import Zimmer_3 from './../../assets/images/zehlendorf/Zimmer-3.webp';
import Zimmer_4 from './../../assets/images/zehlendorf/Zimmer-4.webp';


function ZehlendorfImpressions() {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
      Appartement,
      Garten_mit_Freizeitbereich,
      Haus_Giovanni,
      Haus_Giovanni_Seitenansicht,
      Haus_Pius_Hinteransicht,
      Haus_Pius_Vorderansicht,
      Küche_Haus_Pius,
      Therapieraum_mit_Baellebad,
      Zimmer_2,
      Zimmer_3,
      Zimmer_4
    ];
    const text = [
      "Appartement im Haus",
      "Garten mit Freizeitbereich",
      "Haus Giovanni",
      "Haus Giovanni Seitenansicht",
      "Haus Pius Hinteransicht",
      "Haus Pius Vorderansicht",
      "Küche des Haus Pius",
      "Therapieraum mit Bällebad",
      "Doppelzimmer im Haus Pius",
      "Einzelzimmer im Haus Pius",
      "Doppelzimmer im Haus Pius",
    ];
  
    const openImageViewer = useCallback((e, index) => {
      if(e.type === "keypress" && e.charCode !== 13){
        return;
      }
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);
  
    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };

    return (
      <div className="text-container">
        <h1>Zehlendorf Impressionen</h1>
        <div className="impression-wrapper">
          {images.map((src, index) => (
            <div key={ index } className="img-element" tabIndex="0" onClick={ (e) => openImageViewer(e,index) } onKeyPress={ (e) => openImageViewer(e,index) } > 
              <div className="img-loader">
                <img
                  src={ src }
                  alt={text[index] + " in Berlin Zehlendorf"}
                />
              </div>
              <p aria-hidden='true'>{text[index]}</p>
            </div>
          ))}
        

          {isViewerOpen && (
            <ImageViewer
              src={ images }
              currentIndex={ currentImage }
              disableScroll={ true }
              closeOnClickOutside={ true }
              onClose={ closeImageViewer }
            />
          )}
        </div>
      </div>
    )
}

export default ZehlendorfImpressions
