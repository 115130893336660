import React, { useState } from 'react'
import './Navbar.scss';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import piusImg from './../../assets/images/pius-title.webp';
import * as Constants from './../../Constants';

function Navbar() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const handleNavClick = (e) => {
        let currentNavItem = $(e.target.parentNode);
        let currentSubMenu = $(e.target.parentNode.parentNode).find(".nav-sub-menu");

        if(currentSubMenu.hasClass("active")){
            currentSubMenu.removeClass("active");
            currentNavItem.removeClass("active");
        }else{
            $(".nav-sub-menu.active").removeClass("active");
            $(".nav-item.active").removeClass("active");

            currentSubMenu.addClass("active");
            currentNavItem.addClass("active");
        }
    }

    const handleMainKeyPress = (e) => {
        if(e.charCode === 13){
            let currentNavItem = $(e.target.parentNode);
            if(currentNavItem.hasClass("tab-active")){
                currentNavItem.removeClass("tab-active")
            }else{
                $(".nav-item.tab-active").removeClass("tab-active");
                currentNavItem.addClass("tab-active")
            }
        }
        
    }

    const closeMobileMenu = () => {
        setClick(false);
        setTimeout(removeActiveButtons, 300);
    } 

    const removeActiveButtons = () => {
        $(".nav-sub-menu.active").removeClass("active");
        $(".nav-item.active").removeClass("active");
        $(".nav-item.tab-active").removeClass("tab-active");
    } 

    const tree = [
        {
            text: "Unsere Stiftung",
            subs: [
               /*  {
                    text: "Freiplatz-App",
                    link: Constants.APP_ROUTE
                }, */
                {
                    text: "Chronik",
                    link: Constants.CHRONIK_ROUTE
                },
                {
                    text: "75 Jahre Stiftung Haus Pius XII",
                    link: Constants.CELEBRATION_ROUTE
                },
                {
                    text: "Stellenangebote",
                    link: Constants.JOB_OFFERS_ROUTE
                },
                {
                    text: "Service / Downloads",
                    link: Constants.SERVICE_DOWNLOAD_ROUTE
                }
            ]
        },
        {
            text: "Berlin-Zehlendorf",
            subs: [
                {
                    text: "Unsere Einrichtung",
                    link: Constants.ZEHLENDORF_LOCATION_ROUTE
                },
                {
                    text: "Unsere Angebote",
                    link: Constants.ZEHLENDORF_OFFERS_ROUTE
                },
                {
                    text: "Fotos / Impressionen",
                    link: Constants.ZEHLENDORF_IMPRESSIONS_ROUTE
                }
            ]
        },
        
        {
            text: "Berlin-Biesdorf",
            subs: [
                {
                    text: "Unsere Einrichtung",
                    link: Constants.BIESDORF_LOCATION_ROUTE
                },
                {
                    text: "Unsere Angebote",
                    link: Constants.BIESDORF_OFFERS_ROUTE
                },
                {
                    text: "Fotos / Impressionen",
                    link: Constants.BIESDORF_IMPRESSIONS_ROUTE
                }
            ]
        },
    ];

    return (
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo">
                        {/* Stiftung Haus Pius XII  */}
                        <img src={piusImg} alt="Logo der Stiftung Haus Pius XII"/>
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <div className={click ? 'nav-menu active' : 'nav-menu'}>
                        {tree.map((element, i) => {
                            return (
                                <div key={i} className="nav-div-wrapper" >
                                    <div className='nav-item'>
                                        <p className='nav-links' tabIndex="0" onClick={handleNavClick} onKeyPress={handleMainKeyPress} role="button">
                                            {element.text}
                                        </p>        
                                    </div>
                                    <div className="nav-sub-menu">
                                        {element.subs.map((sub, i) => {
                                            return (
                                                <div key={i} className='nav-sub-item'>
                                                    <Link to={sub.link} className='nav-sub-links' tabIndex="0" onClick={closeMobileMenu} role="link">
                                                        {sub.text}
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </nav>
            <div className="clearing"></div>
        </>
    )
}

export default Navbar
