import React from 'react'

function BiesdorfOffers() {
    return (
        <div className="text-container font-size-15">
            <div className="middle">
                <h1>Berlin-Biesdorf – Unsere Angebote</h1><br/>
                Eine stationäre Unterbringung in unserer Einrichtung Biesdorf mit aktuell acht Plätzen kann im Rahmen des aktuellen Trägervertrages in einem Gruppenangebot mit Intensivleistung (A 3) erfolgen.
                <br/>
                <br/>
                Auch im Haus Biesdorf bietet ein multiprofessionelles Team, welches aus Erzieherinnen und Erziehern sowie einer Reinigungs- und einer Hauswirtschaftskraft besteht, Hilfe und Unterstützung im Rahmen einer 24/7-Betreuung an.
            </div>
        </div>
    )
}

export default BiesdorfOffers
