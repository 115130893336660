import React from 'react'

function ZehlendorfOffers() {
    return (
        <div className="text-container font-size-15">
            <div className="middle">
                <h1>Berlin-Zehlendorf – Unsere Angebote</h1><br/>
                Eine stationäre Unterbringung im Haus Pius oder im Haus Giovanni in Berlin-Zehlendorf kann im Rahmen der aktuellen Trägerverträge mit folgenden Leistungen angeboten werden: 
                <br></br>
                <br></br>
                <ul>
                    <li>Fünf Plätze in einem familienanalogen Gruppenangebot (B 3) Intensivleistung nach §§ 34 SGB VIII (möglicher Kinderschutz)</li>
                    <li>Acht Plätze in einem  Gruppenangebot (A 3) Intensivleistung nach § 34 SGB VIII</li>
                </ul>
                <br></br>
                Das auf demselben Grundstück befindliche Haus Giovanni bietet im Rahmen der aktuellen Trägerverträge zusätzlich folgende Leistung: 
                <br></br>
                <br></br>
                <ul>
                    <li>Zwei Verselbständigungsplätze im Rahmen des A 3-Angebotes mit geringer Betreuungsdichte nach § 34 SGB VIII mit einem individuellen hilfeplanabhängigen Stundensetting im Umfang von sechs, neun oder zwölf Wochenstunden.</li>
                </ul>

             
            </div>
        </div>
    )
}

export default ZehlendorfOffers
